import ReactGA from "react-ga4";

const initializeGA = () => {
  ReactGA.initialize("G-PXNE08LY53"); // Replace with your Measurement ID
};

const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
};

export { initializeGA, logPageView };
