import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import "../../styles/BigBlogCard.css";
import Footer from "../common/Footer";
import { BLOG_POSTS } from "../../constants";

const BigBlogCard = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const [currentPost, setCurrentPost] = useState(
    location.state || BLOG_POSTS[0].posts[0]
  );

  useEffect(() => {
    if (location.state) {
      setCurrentPost(location.state);
    }
  }, [location.state]);

  const handleNavigation = (post) => {
    setCurrentPost(post);
  };

  // const handleReadMore = (post) => {
  //   setCurrentPost(post);
  // };

  const rendercontent = (content) => {
    if (!content) return null;
    return content.split("\n").map((item, index) => <p key={index}>{item}</p>);
  };

  const renderBlogPosts = () => {
    return BLOG_POSTS[0].posts
      .filter((post) => post.id !== currentPost.id)
      .map((post, index) => (
        <Card
          key={index}
          className="blogs-list-item"
          onClick={() => handleNavigation(post)}
        >
          <CardContent className="blogs-list-item-content">
            <img
              src={post.image}
              alt={post.title}
              className="blogs-list-image"
            />
            <div className="blogs-list-text">
              <Typography
                variant="h6"
                component="div"
                className="blogs-list-item-title"
              >
                {post.title}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                className="blogs-list-item-subtitle"
              >
                {post.subtitle}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                className="blogs-list-item-date-time"
              >
                {post.dateTime}
              </Typography>
              {/* <Typography
                variant="body2"
                component="div"
                className="blogs-list-item-readmore"
                onClick={() => handleReadMore(post)}
              >
                Read More
              </Typography> */}
            </div>
          </CardContent>
        </Card>
      ));
  };

  return (
    <>
      <div className="big-blog-card-container">
        <Grid container spacing={4} alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <Card className="big-blog-card">
              <CardContent>
                <div className="social-icons-container">
                  {/* Add your social icons here */}
                </div>
                {currentPost.image && (
                  <img
                    src={currentPost.image}
                    alt={currentPost.title}
                    className="big-blog-card-image"
                  />
                )}
                <Typography
                  variant="h4"
                  component="div"
                  gutterBottom
                  className="blog-title"
                >
                  {currentPost.title}
                </Typography>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  className="blog-subtitle"
                >
                  {currentPost.subtitle}
                </Typography>
                {rendercontent(currentPost.content)}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className="blogs-list">
              <Typography
                variant="h5"
                component="div"
                className="blogs-list-title"
              >
                Other Blogs
              </Typography>
              {renderBlogPosts()}
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default BigBlogCard;
