import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Avatar,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import "../../styles/Toast.css";

const Toast = ({ notification, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  return (
    <>
      {notification && notification.type === "tweet" ? (
        <Card className={`toast-container ${visible ? "show" : ""}`}>
          <CardHeader
            avatar={
              <Avatar
                src={notification.image}
                alt=""
                className="toast-avatar"
              />
            }
            action={
              <IconButton aria-label="close" onClick={handleClose}>
                <FaTimes className="toast-close" />
              </IconButton>
            }
            title={notification.title}
            subheader={notification.time}
            className="toast-header"
          />
          <CardContent className="toast-content">
            <Typography variant="body2" component="p" className="toast-text">
              {notification.message}
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Alert
          severity={notification.severity || "info"}
          onClose={handleClose}
          className={`toast-alert ${visible ? "show" : ""}`}
        >
          <AlertTitle>{notification.title}</AlertTitle>
          {notification.message}
        </Alert>
      )}
    </>
  );
};

export default Toast;
