import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  useMediaQuery,
} from "@mui/material";
import "../../styles/Tokenomics.css";
import { TOKENOMICS_ITEMS } from "../../constants";

const Tokenomics = () => {
  const isMobile = useMediaQuery("(max-width:768px)");

  return (
    <div className="section-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {TOKENOMICS_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {TOKENOMICS_ITEMS[0].subtitle}
      </Typography>
      <Card className="tokenomics-card">
        <CardContent>
          {isMobile && (
            <Grid item xs={12}>
              <img
                src={TOKENOMICS_ITEMS[0].imageMobile}
                alt="Tokenomics 1"
                className="tokenomics-image"
              />
            </Grid>
          )}
          {!isMobile && (
            <Grid item xs={12} sm={6}>
              <img
                src={TOKENOMICS_ITEMS[0].image}
                alt="Tokenomics 2"
                className="tokenomics-image"
              />
            </Grid>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Tokenomics;
