import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./styles/index.css";

const setGlobalCursorStyle = () => {
  // Set default cursor for the body
  document.body.style.cursor = 'url("/assets/ffk.png") 16 16, auto';

  // Function to update cursor style based on element type
  const updateCursor = (element) => {
    const isPointerCursor =
      window.getComputedStyle(element).cursor === "pointer";
    if (
      element.tagName === "A" ||
      isPointerCursor ||
      element.tagName === "BUTTON" ||
      (element.tagName === "IMG" && element.closest("a"))
    ) {
      element.style.setProperty(
        "cursor",
        'url("/assets/ffk2.png") 16 16, pointer',
        "important"
      );
    } else {
      element.style.setProperty(
        "cursor",
        'url("/assets/ffk.png") 16 16, auto',
        "important"
      );
    }
  };

  // Apply cursor styles to all elements
  const allElements = document.querySelectorAll("*");

  allElements.forEach((element) => {
    element.addEventListener("mouseover", () => {
      updateCursor(element);
    });

    element.addEventListener("mouseout", () => {
      element.style.setProperty(
        "cursor",
        'url("/assets/ffk.png") 16 16, auto',
        "important"
      );
    });
  });
};

const addScrollEffect = () => {
  window.addEventListener("scroll", () => {
    const navbar = document.querySelector(".navbar");
    if (navbar) {
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    }
  });
};

const Main = () => {
  useEffect(() => {
    addScrollEffect();
    setGlobalCursorStyle();
  }, []);

  return (
    <Router>
      <App />
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Main />);
