import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import "../../styles/Story.css";
// import expressionImage from "../../assets/images/granny_showing_stars.webp";
import { RenderContent } from "../utils/utilities";
import { STORY_ITEMS } from "../../constants";

const Story = () => {
  return (
    <div className="section-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {STORY_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {STORY_ITEMS[0].subtitle}
      </Typography>
      <Card className="story-section-card">
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src={STORY_ITEMS[0].image}
                alt="Expression"
                className="story-section-image"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                className="content"
              >
                {RenderContent(STORY_ITEMS[0].content)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default Story;
