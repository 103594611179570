import React, { useEffect, useState } from "react";
import "../../styles/LoadingScreen.css";
import icon from "../../assets/icons/ffk.png";

const quotes = [
  { key: "1", quote: "“Look, a Flying Fuck!”", by: "- Granny Nakamoto" },
  {
    key: "2",
    quote: "“What! Satoshi Nakamoto is your secret lover?”",
    by: "- Grandson",
  },
  {
    key: "3",
    quote: "“Join The Expressions Revolution”",
    by: "- #CryptoGranny",
  },
  {
    key: "4",
    quote: "“50% of Our total supply is airdropped for FREE!”",
  },
  {
    key: "5",
    quote: "“All Flying Fucks Used as Expressions will be BURNED!”",
  },
  {
    key: "6",
    quote: "“The First Crypto Coin With Two Sides”",
    by: "- Granny Nakamoto",
  },
  {
    key: "7",
    quote: "Making “Not Giving a Flying Fuck” a Reality",
    by: "- Granny Nakamoto",
  },
  {
    key: "8",
    quote: "Participate in Pre-Sale to Earn Free NFT",
  },
  {
    key: "9",
    quote: "Story of a simple cookie that sparked a revolution",
  },
  {
    key: "10",
    quote: "“Yes, Fucks do fly!”",
    by: "- Granny Nakamoto",
  },
  {
    key: "11",
    quote: "“Your chance to own a flying fuck.”",
  },
  {
    key: "12",
    quote: "“Balancing the yin and yang of social media.”",
    by: "- Granny Nakamoto",
  },
  {
    key: "13",
    quote:
      "“Tired of Meme Coins that do nothing? Come join Expressions Revolution”",
    by: "- Granny Nakamoto",
  },
  {
    key: "14",
    quote: "“Express, whatever floats your Fucking Boat”",
  },
];

const LoadingScreen = ({ setLoading }) => {
  const [quote, setQuote] = useState(null);

  useEffect(() => {
    const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
    setQuote(randomQuote);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2800);

    return () => clearTimeout(timer);
  }, [setLoading]);

  const iconsArray = Array.from({ length: 10 });

  const getRandomPositionAndDirection = () => {
    const side = Math.floor(Math.random() * 4);
    const position = Math.random() * 100;
    switch (side) {
      case 0: // from top
        return {
          top: `-50px`,
          left: `${position}vw`,
          animationDirection: "fromTop",
        };
      case 1: // from right
        return {
          top: `${position}vh`,
          left: `100vw`,
          animationDirection: "fromRight",
        };
      case 2: // from bottom
        return {
          top: `100vh`,
          left: `${position}vw`,
          animationDirection: "fromBottom",
        };
      case 3: // from left
      default:
        return {
          top: `${position}vh`,
          left: `-50px`,
          animationDirection: "fromLeft",
        };
    }
  };

  const formatQuote = (quote) => {
    if (
      quote &&
      quote.quote.length > 35 &&
      (quote.quote.includes("-") || quote.quote.includes("?"))
    ) {
      const regex = /(\?|-\s)/;
      const match = quote.quote.match(regex);
      if (match) {
        const index = match.index + match[0].length;
        const firstPart = quote.quote.slice(0, index);
        const secondPart = quote.quote.slice(index);
        return (
          <>
            <span className="quote">{firstPart}</span>
            <br />
            <span className="quote">{secondPart}</span>
            {quote.by && <div className="quoteby">{quote.by}</div>}
          </>
        );
      }
    }
    return (
      <>
        <span className="quote">{quote.quote}</span>
        {quote.by && <div className="quoteby">{quote.by}</div>}
      </>
    );
  };

  return (
    <div className="loading-screen">
      {quote && <h1 className="">{formatQuote(quote)}</h1>}
      <div className="icon-container">
        {iconsArray.map((_, index) => {
          const { top, left, animationDirection } =
            getRandomPositionAndDirection();
          return (
            <img
              key={index}
              src={icon}
              alt="Icon"
              className={`animated-icon ${animationDirection}`}
              style={{
                top,
                left,
                animationDelay: `${index * 0.5}s`,
                animationDuration: `${Math.random() * 2 + 3}s`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default LoadingScreen;
