import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import "../../styles/Tools.css";
import { DEFAME_TOOLS_ITEMS as tools } from "../../constants";

const DeFameTools = () => {
  return (
    <div className="tools-container">
      <Card>
        <CardContent>
          <Grid container spacing={4}>
            {tools.map((tool, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card className="tool-card">
                  <CardContent className="tool-content">
                    <img
                      src={tool.icon}
                      alt={tool.title}
                      className="tool-icon"
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      className="tool-title"
                    >
                      {tool.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      component="div"
                      className="tool-description"
                    >
                      {tool.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeFameTools;
