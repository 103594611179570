import {
  faRedditAlien,
  faXTwitter,
  faTelegram,
} from "@fortawesome/free-brands-svg-icons";

// NAVIGATION
export const NAV_LINKS = [
  { to: "#tokenomics", key: "tokenomics", label: "Tokenomics" },
  { to: "#roadmap", key: "roadmap", label: "Roadmap" },
  { to: "#nfts", key: "nfts", label: "NFTs" },
  { to: "#faqs", key: "faqs", label: "FAQs" },
  { to: "#blogs", key: "blogs", label: "Blogs" },
  {
    to: "/assets/docs/Whitepaper.pdf",
    key: "pdf",
    label: "Whitepaper",
    external: true,
  },
];

//Landing
export const LANDING_ITEMS = [
  {
    heading: "The First Two-Sided Crypto Coin!",
    title: "FLYING FUCK",
    subtitle: `Join Granny Nakamoto's expressions revolution #CryptoGranny`,
  },
];

// Story
export const STORY_ITEMS = [
  {
    title: "CryptoGranny & The Flying Fuck",
    subtitle: "The Ultimate Expressions Cryptocurrency!",
    image: require("./assets/images/granny_showing_stars.webp"),
    content: `Flying Fuck is your ultimate tool for true expression that lets you express both sides the good and the bad, making the world a better and more balanced place.

One magical night, Granny Nakamoto, with her tech-savvy grandson by her side, saw a shooting star and exclaimed, "Look, a Flying Fuck!" Inspired by her secret lover (Satoshi Nakamoto) and fueled by her world’s best cookies, they birthed Flying Fuck Cryptocurrency—the world's first crypto with two sides.

IGAF (I Give a Flying Fuck) shows strong support, flip it over to IDGAF (I Don’t Give a Flying Fuck) expresses disapproval or indifference to balance the yin and yang of social media.

Our DeFame Lens browser extension lets you see, and give or not give a flying fuck to anyone on social media without logging in or collecting your data. Plus, Flying Fuck used in transactions is burnt, making our currency deflationary.

CryptoGranny believes everyone has the right to express themselves freely. She's giving away 50% of the total supply in airdrops for free, while she and her grandsons only keep 1%.

Join the Expressions revolution and make your voice heard—because sometimes, you just don’t give a flying fuck!`,
  },
];

// Defame
export const DEFAME_ITEMS = [
  {
    title: "The DeFame Platform",
    subtitle: "Where Expressions Meet Blockchain with a Dash of Humor!",
    content: `Welcome to the dawn of the expression revolution, In a world censored by faceless algorithms and corporate overlords dictating the bounds of your online existence. What if your Beliefs could truly make a difference? and you have a real say in shaping our world? - Let’s break free from the shackles of corporate control, and declare digital independence where your opinions matter more than you ever thought possible.
DeFame is a blockchain platform with real utility in the crypto world, where every opinion is a beacon, every expression is a symphony and every voice resonates with significance. Prepare to witness a new era where expression knows no bounds, fame is but a side effect of speaking your truths and likes and followers aren’t just a number but a symbol of your voice resonating across platforms. Flying Fuck is built with the Defame platform principles in mind and so are the Sh!t and Rat's Ass Coins.
`,
  },
];

// DeFame tools
export const DEFAME_TOOLS_ITEMS = [
  {
    icon: require("./assets/icons/tools/DeFameLensIcon.webp"),
    title: "The DeFame Lens",
    description: `This browser extension makes you feel like having a pair of X-Ray glasses that transforms your social media browsing into an immersive virtual game of “Who gives a FU*K?" - Trust me, you'll never see social media the same way again!`,
  },
  {
    icon: require("./assets/icons/tools/DeFameBubblesIcon.webp"),
    title: "The DeFame Bubbles",
    description:
      "Picture this tool as a fireworks show of digital expressions, with each burst of color conveying who garnered more flying fucks or who remained the most fuck-less. It's a visual feast for the eyes where the size of your bubbles truly matters.",
  },
  {
    icon: require("./assets/icons/tools/DeFameCampaignsIcon.webp"),
    title: "The DeFame Campaigns",
    description:
      "It's philanthropy with a twist, where every FUK given contributes to a cause's coffers, and every FUK not given... Well, let's just say it adds a little extra burn to the digital flames. It’s like Go Fund me, only much better and balanced. ",
  },
];

//Blog Posts
export const NFT_ITEMS = [
  {
    title: "Own a Flying Fuck NFT!",
    subtitle:
      "Lucky winners every month receive a free NFT! Particiapate in the Pre-Sale to enter the contest.",
    nfts: [
      {
        key: 1,
        title: "Scarecrow in daylightS",
        image: require("./assets/nfts/nft1.png"),
        content: "Scarecrow in daylightS",
      },
      {
        key: 2,
        title: "3D Digital Artwork",
        image: require("./assets/nfts/nft2.png"),
        content: "3D Digital Artwork",
      },
      {
        key: 3,
        title: "Abstract Geometry",
        image: require("./assets/nfts/nft3.png"),
        content: "Abstract Geometry",
      },
      {
        key: 4,
        title: "Futuristic Cityscape",
        image: require("./assets/nfts/nft4.png"),
        content: "Scarecrow in daylight",
      },
      {
        key: 5,
        title: "Mystic Forest",
        image: require("./assets/nfts/nft5.png"),
        content: "Mystic Forest",
      },
      {
        key: 6,
        title: "Galactic Warrior",
        image: require("./assets/nfts/nft6.png"),
        content: "Galactic Warrior",
      },
      {
        key: 7,
        title: "Cyberpunk Vision",
        image: require("./assets/nfts/nft7.png"),
        content: "Cyberpunk Vision",
      },
      {
        key: 8,
        title: "Fantasy Creature",
        image: require("./assets/nfts/nft8.png"),
        content: "Fantasy Creature",
      },
      {
        key: 9,
        title: "Digital Portrait",
        image: require("./assets/nfts/nft9.png"),
        content: "Digital Portrait",
      },
      {
        key: 10,
        title: "Neon Dreams",
        image: require("./assets/nfts/nft10.png"),
        content: "Neon Dreams",
      },
    ],
  },
];

//Roadmap
export const ROADMAP_ITEMS = [
  {
    title: "The Flight Plan",
    subtitle: "Our journey to flipping the world, one Flying Fuck at a time!",
    items: [
      {
        time: "Phase 1",
        title: "The Big Bang",
        description:
          "Website goes live, contract gets written, social channels are born.",
        icon: "fab fa-facebook-f",
      },
      {
        time: "Phase 2",
        title: "Development",
        description: "Contract audit, presale begins, community building.",
        icon: "fab fa-facebook-f",
      },
      {
        time: "Phase 3",
        title: "Awareness",
        description:
          "Marketing initiation, CoinMarketCap application, CoinGecko application.",
        icon: "fab fa-facebook-f",
      },
      {
        time: "Phase 4",
        title: "Expansion",
        description: "First DEX listings, DEXTools update, BirdEye update.",
        icon: "fab fa-facebook-f",
      },
      {
        time: "Phase 5",
        title: "Cosmic Travel",
        description:
          "First CEX listings, continued marketing, community incentives.",
        icon: "fab fa-facebook-f",
      },
    ],
  },
];

export const TOKENOMICS_ITEMS = [
  {
    title: "Counting The Flying Fucks",
    subtitle: "Because every Fuck matters in our crazy crypto world!",
    image: require("./assets/images/tokenomics/tokenomics.png"),
    imageMobile: require("./assets/images/tokenomics/tokenomics_mobile.png"),
  },
];

//Notifications
export const NOTIFICATION_ITEMS = [
  {
    key: 1,
    type: "tweet",
    title: "Granny Nakamoto",
    subtitle: "@GrannyNakamoto",
    image: require("./assets/images/profile/granny.webp"),
    message: `Hey Kiddo,
    I am giving away 50% of our FLYING FUCKS. Claim your free Airdrop starting 4th of July`,
    time: "a few seconds ago",
  },
  {
    key: 2,
    type: "tweet",
    title: "Melon Musk",
    subtitle: "@ElonMusk",
    image: require("./assets/images/profile/musk.jpg"),
    message:
      "I have my Trust in #CryptoGranny, Who better to lead a revolution. I am thinking of dumping #DOGE and jump on the #FlyingFUCK wagon",
    time: "a few seconds ago",
  },
  {
    key: 4,
    type: "notification",
    severity: "info",
    message: "Presale starts 4th of July",
  },
];

// About
export const ABOUT_ITEMS = [
  {
    title: "Who is #CryptoGranny ?",
    subtitle: "The power of a simple cookie that sparked a revolution",
    image: require("./assets/blogs/crypto_granny.webp"),
    content: `Flying Fuck springs from the creative mind of Granny Nakamoto, a visionary who passionately advocates for a liberated society within "The Nakamoto" ethos. While her beloved Satoshi advocated for financial autonomy as the key to empowering the people, Granny - a true patriot, cherishes the intrinsic worth of people's voices above all else. She dreams of a world unchained from centralized dominance, where individuals enjoy the genuine freedom of expression promised by the First Amendment.
Granny Nakamoto's legendary cookies weren't just a treat for the taste buds; they were also fuel for her grandsons to pioneer a groundbreaking blockchain technology where every byte of data carried the essence of the Nakamoto family's wisdom. Her cookies became not just a symbol of familial indulgence but also an unconventional revolution where compassion and indifference became quantifiable with a secret ingredient in a recipe for global change.`,
  },
];

//Blog Posts
export const BLOG_POSTS = [
  {
    title: "Blog Posts",
    subtitle: "Stay updated with our latest news and articles.",
    posts: [
      {
        id: 1,
        dateTime: "May 14, 2024 • 2 min read",
        title: "Who Is Crypto Granny?",
        subtitle: "A Tale of Blockchain, Free Speech, and Fresh Cookies",
        image: require("./assets/blogs/crypto_granny.webp"),
        content: `Well, howdy there, dear readers! It's Granny Nakamoto here, ready to spill the beans on a story that's equal parts tech, sass, and a sprinkle of irreverence. You see, once upon a time, I was the apple of the eye of none other than the infamous Satoshi Nakamoto. While he was all about financial freedom and decentralization, I had my heart set on preserving the First Amendment rights of free speech. We spent countless hours dreaming about how we wanted the world to be—a place where every opinion could be heard loud and clear, free from the censorship of those stuffy traditional social media platforms.

                  Here's the thing, sugar: the world we live in has always had divided opinions. But lately, it's like things are just super unfair. It's mostly the positive "likes" getting all the attention. You know, the "I like this" or "I agree with that" comments. Seriously, who doesn’t like that? But what about the dislikes? What about me not giving a flying fuck about something or someone because I just don’t approve of it?
                  You see, a world where we can agree or disagree openly is a world that’s fair and balanced. It's our right to speak our minds, just like the First Amendment says. We liberate ourselves when we express our true feelings, good or bad. All opinions matter to keep things in check. So, what about those disagreements when a project or opinion is out there for everyone to consider?
                  But enough about the past; let’s talk about the future! I was sitting on the porch one summer evening, with my grandsons eating cookies and watching the stars. A falling star appeared, and I couldn't help but exclaim, "Look, a flying fuck!" In that moment, My grandson, who had been munching on cookies, glued to his laptop, he looked up and grinned “Granny that’s a shooting star, not a flying fuck!  Fucks don’t fly.  But hey, make a wish.”  I chuckled and told him “I wish fucks could fly, but I’m more worried about your generation.  With all your gadgets and social media people can’t express themselves without getting blocked or censored.  I really wish that could change…so that’s my wish.”  It was then that an idea was born. I told my grandsons the story of how important the First Amendment is to me and how I wanted everyone to be able to light up the world with their opinions—just like that star had lit up the night sky. With a little help from my tech-savvy grandsons (who, by the way, I kept fueled with my “world’s best cookies” fresh out of the oven), we came up with a plan how we might manifest my wish, rolled up our sleeves and got to work. Together, we created the DeFame platform, a sanctuary where free speech can thrive forever, thanks to the magic of blockchain technology.
                  But wait, there's more! In our rebellious spirit, we didn’t stop at just a platform. Oh no, we went a step further and created the Flying Fuck coin—my favorite phrase turned into digital gold! This boldly explicit coin is a true original, sporting two sides and designed to be offensive in the most delightful way. It's all about elevating opinions to a whole new level on the global stage, using social media platforms and blockchain technology. 

                  So, gather 'round, my fellow irreverent rebels. Together, fueled by cookies and courage, let’s create the world we envision, where every voice matters and no opinion is too bold. Join me, Granny Nakamoto, in this revolution, and let’s change the world one Flying Fuck at a time!

                  With a wink and a cookie,
                  Granny Nakamoto`,
      },
      {
        id: 2,
        dateTime: "Jun 5, 2024 • 1 min read",
        title: "THE ALMIGHTY",
        subtitle: "The Meme Coin Craze and the Quest for Truth",
        image: require("./assets/blogs/praise_the_fuck.jpg"),
        content: `Hey there, my fabulous followers!

        Granny Nakamoto back in action, and today we're tackling a topic that's been on everyone's lips: meme coins. Yep, those cute, little digital fluffballs that make you chuckle and, if you're lucky, add a few zeros to your bank account. Now, before you grab your pitchforks, hear me out. I know it seems like I bash all those meme coins, but let’s face it… they’ve had a good run in some cases! To each his own, I say, but there's more to life than just a good laugh.

        Here’s the thing, lovelies. Our platform is all about advocating for truth and justice in a world that's rapidly becoming meaner than a cat in a bathtub. Social change doesn't just pop out of nowhere like a rabbit from a hat; it needs a catalyst. And that's where we come in. We wanted our opinions housed with blockchain technology so the "real feels" of the world are no longer hidden behind a curtain of emojis and hashtags.

        Everything in our lives is manipulated by the media in one form or another. Remember the good old days when we fought for things to be real? Back in my day, we were all about raising our voices against anything we felt had a hidden agenda or was unjust in any way. And guess what? We made an impact! Nowadays,It seems like everyone’s as ambivalent as a teenager deciding what to wear on their first date."


        So, yes, I had to poke a little fun at those silly meme coins. It’s my way of shaking things up, of saying, "Hey, wake up and smell the blockchain!" I feel it’s my responsibility to encourage a whole new generation to let their voices be heard! Time waits for no one, my dears. We have to "be the change we wish to see in the world." If not us, then who?

        Let's aim higher, folks. Let's advocate for a world where truth and justice aren't just hashtags but realities we live by. So go ahead, enjoy your meme coins, but remember, life is about more than cute dogs on the internet. It’s about making a difference.

        Until next time, stay sassy and stay true!

        Granny Nakamoto`,
      },
      {
        id: 3,
        dateTime: "May 20, 2024 • 2 min read",
        title: "Two-Sided Crypto Revolution",
        subtitle: "Why Our Coin Stands Out",
        image: require("./assets/blogs/dummy_meme_coins.jpg"),
        content: `Well, howdy there, dear readers! Granny Nakamoto here, ready to serve up a heaping helping of truth, wisdom, and a touch of sass. Today, I want to chat about why our crypto, the Flying Fuck coin, is a cut above those run-of-the-mill meme coins. You know the ones I’m talking about—Doge, Pepe, Shiba, and the like. They may be all sparkly graphics and trendy names, but let’s face it: they’re about as useful as a chocolate teapot.

                  Now, don’t get me wrong, those meme coins have their place. But in Granny’s world, crypto should actually have a purpose! That’s where the Flying Fuck coin comes in. It’s not just another shiny paperweight; it’s a tool for real change. Our unique two-sided coin gives you the option of actually “giving a flying fuck”—showing support on one side and, on the flip side, withholding support when you don’t. It’s all about elevating opinions and making your voice heard in a meaningful way.

                  Let’s take a closer look at these young whippersnapper coins. Doge? Cute dog, but what’s it doing for you besides looking adorable? Pepe? A fun frog, but not much more than a giggle. Shiba? Sure, it’s got a following, but what’s it really accomplishing? In Granny’s book, if your crypto isn’t working hard, it’s just another shiny distraction.

                  Our Flying Fuck coin, on the other hand, is designed to be offensive in the most delightful way. It’s a bold statement, a tool for activism, and a way to put your money where your mouth is. Whether you’re showing support or withholding it, you’re making a clear, impactful statement on the global stage. And with our DeFame website, we’re ensuring that free speech is preserved forever, thanks to the magic of blockchain technology.

                  So, my dear rebels, it’s time to show these trendy meme coins how it’s done. With a mix of sharp wit, a sprinkle of sarcasm, and a whole lot of wisdom, we’re creating a world where crypto has a real purpose. Because if your crypto isn’t making a difference, it’s just another shiny paperweight.

                  Join me, Granny Nakamoto, in this revolution. Let’s change the world, one Flying Fuck at a time!

                  With a wink and a cookie,
                  Granny Nakamoto`,
      },
      {
        id: 4,
        dateTime: "May 22, 2024 • 3 min read",
        title: "Flying Fuck Revolution",
        subtitle: "A Coin with Attitude",
        image: require("./assets/blogs/ffk.jpg"),
        content: `Howdy there, dear readers! It’s Granny Nakamoto, back at it again with another tale of sass, wisdom, and a sprinkle of irreverence. Today, I’m going to share the hilarious and heartfelt story behind the creation of the Flying Fuck coin—straight from my favorite expression, “I don’t give a flying fuck.”

                  Now, let’s get one thing straight: making a fuck fly isn’t an easy task.First, there was finding the “right” finger, then we had to give it wings, make it attractive, and add a sprinkle of Granny magic. But here’s the kicker: this isn’t just any coin. It’s the first of its kind with a two-sided design—one side to show strong support and the other to withhold that support in defiance. This baby packs a punch!

                  Giving a Flying Fuck token signals strong support, while withholding it indicates strong opposition. It’s like saying, “Hey, you’re worth a Flying Fuck!”—or not. It’s a bold, cheeky way to make your opinions known on the global stage. And because we believe in fairness, we’re giving away 50% of the coins through scheduled airdrops. 

                  But wait, there’s more! Deflation ensures that every time a Flying Fuck is given (or not) using the DeFame tools to express an opinion, there are fewer Flying Fucks left in the world. So with each one that’s used as an expression, those tokens will then be “burned”, so the value of the remaining tokens increases exponentially! You’re not just making a statement—you’re making an impact.

                  And the fun doesn’t stop there. With staking, minting, and NFT creation, the sky’s the limit for this coin. Each month, one lucky individual who purchases coins will receive a special NFT in a drawing. And keep an eye out for new coins in the pipeline: Sh!t and Rats Azz, which will be incentives for those willing to stake and mint their Flying Fuck coins.

                  So, my dear rebels, join me in this revolution. Together, let’s make a strong statement and change the world—one Flying Fuck at a time!

                  With a wink and a cookie,
                  Granny Nakamoto`,
      },
      {
        id: 5,
        dateTime: "May 22, 2024 • 2 min read",
        title: "IDGAF",
        subtitle: "The Yin and Yang of Life",
        image: require("./assets/blogs/idgaf.jpg"),
        content: `Well, hello there, my dear readers!

                It's Granny Nakamoto here, your sassy sage of wisdom, back with another slice of life served with a generous helping of humor. Today, we're diving into the mystical world of Yin and Yang. Yes, darlings, everything has two sides, and it's high time we remember that!

                Let’s start with a little rant about society, shall we? We've become so anti-offensive it's like we're all walking on eggshells. Remember when only the winning team got a trophy? Oh, those were the days! Now, every kid on the sports team gets a shiny participation trophy so no one feels left out. Really? What's the lesson here? "Congratulations, kiddo, for showing up!" Well, guess what, showing up is just the first step. It’s hard work and dedication that should earn you that coveted piece of plastic.

                Now, don't get me wrong, I'm all for encouragement, but come on! We've forgotten how to be honest. It's like everyone is scared to speak their truth because it might "offend" someone. Well, newsflash! The truth isn't always wrapped up in a pretty bow. Sometimes, it's harsh, and that's okay. So what if only the winning team gets a trophy? Isn't that supposed to teach us how hard work and dedication lead to success? No wonder so many kids are medicated these days! They haven't been taught to weather the storms of life, so they expect everything to come easily, without any effort.

                When I envisioned giving power back to the people, I wanted both sides of the coin! If you strongly agree with someone or something, you should have a strong say in it! Conversely, if you're vehemently against someone or something, what's so wrong with voicing that? As a society, we'd be a pretty boring lot if we all thought the same about everything. Healthy debate makes us a more vibrant society...at least if we keep it respectable.

                That's why I wanted people to choose for themselves which side of the coin their opinions lie on. One side for support and one side to oppose. We must have both dark and light to have truth in the world. It's the balance, the Yin and Yang, that makes life interesting.

                So, let's embrace both sides, my lovelies. Let's teach our kids that life isn't always fair, that hard work matters, and that it's okay to have differing opinions. Speak your truth, but do it with respect. After all, it’s our differences that make this world a colorful place to live in.

                Until next time, stay sassy and stay true!
        
                Granny Nakamoto`,
      },

      {
        id: 6,
        dateTime: "Jun 22, 2024 • 4 min read",
        title: "The only question?",
        subtitle: "To Give or Not to Give, That is the Question",
        image: require("./assets/blogs/to_give_or_not_to_give.jpg"),
        content: `Hello again, my delightful darlings!

                  It’s your favorite crypto granny, Granny Nakamoto, back to drop some wisdom bombs with a side of sass. Today, I’m channeling my inner Shakespeare. You know the line, "To be, or not to be," from Hamlet? Well, in my world, it’s "To give or not to give, that is the question."

                  You see, when my grandsons and I were conjuring up our DeFame platform and brainstorming the names for our coins, we had a vision. That vision is a world where things are more balanced for EVERYONE! And how are we achieving this, you ask? By air-dropping 50% of our total coins. Yep, you heard that right! Who does that? We do! And why? Because opinions are like assholes; everybody's got one!  But, where does that voice get heard above all the din of the world?  That’s why we developed the DeFame platform….I don’t just want to give you your voice, I built a stage for you to shout it from!

                  Social media was a great starting place for that. At least it was until the powers that be decided to interfere and started censoring us. They’ve given us a ton of reasons for this tactic, none of which I’m buying!  Remember the Facebook "dislike" button? Well, that sure didn’t last long, did it? When did we as a society become so intolerant of opinions that differ from our own? Our philosophy is simple: you can choose to send a Flying FUCK, or choose the other side of the coin to voice your strong disapproval both choices are valid! It’s YOUR choice to make.  That is why we are giving you the first ever two-sided coin.  Talk about a revolution!

                  Let’s dive into some real-world examples to show you just how our DeFame platform can make your opinions count. Buckle up, because we're talking about the dreaded "P" word. That's right, POLITICS! A topic hotter than my kitchen in full cookie-baking mode!
                  Let's start with an obvious example: the angry orange guy. You know who I'm talking about. We're bombarded with statistics all the time about how many people think this guy should be president. BUT what if you could actually make YOUR opinion heard? I see FUCKS flying faster than fur balls in a cat fight on that issue!
                  And while we're on a roll, how about another example? Remember Will Smith's slap that was heard around the world? Oh, the drama! Now, here’s the fun part: Would you give a Flying FUCK to him, showing your approval, or send the IDGAFF side to show you strongly disagreed with his actions? With our two-sided coins, you can make your opinion count in a way that’s loud and clear.
                  Not every voice can be heard, and even fewer have any real impact on things. We WANT people to express their opinions and we WANT their voices to be heard! That’s why all our coins are two-sided—one to give, and one to give a resounding NOT ON YOUR LIFE. It’s said that opinions are "a dime a dozen," but we don’t think it should cost even "a penny for your thoughts" to be heard!  Whether it’s politics or pop culture, our platform lets you express your opinions in the most gloriously sassy way possible. 

                  I’m not gonna lie, we want you to buy our coins, of course, that is what supports this whole revolution. But we also want you to be able to use them freely to express your support or disdain for people, places, causes, things—anything that tickles your fancy or grinds your gears! 

                  So, my dears, the ultimate question remains: To give, or not to give? The power is in your hands.

                  Until next time, stay sassy and stay true!

                  Granny Nakamoto`,
      },
    ],
  },
];

//FAQs

export const FAQ_ITEMS = [
  {
    title: "FAQ's",
    subtitle: "what the Flying Fuck are we doing here!",
    faqs: [
      {
        key: 1,
        question: "Yo Granny, what’s a Flying Fuck?",
        answer:
          "Well, darlin’, it’s the coolest crypto around! It lets you show the world just how much—or how little—you care. Perfect for when you need more than words to express yourself! Flying Fuck is also the world’s first two-sided cryptocurrency because, as I say, a real coin has two sides.",
      },
      {
        key: 2,
        question: "Who cooked up this wild idea?",
        answer:
          "That would be me, your #CryptoGranny, and my tech-whiz grandson. Inspired by my beloved Satoshi Nakamoto, a burning passion for free speech, and my legendary cookies.",
      },
      {
        key: 3,
        question: "How do I score some Flying Fucks?",
        answer:
          "Easy peasy! Head over to www.theflyingfuck.com and join the revolution! We’re showering you with Flying Fucks through our epic airdrops. Don’t miss out, honey! My real supporters can also contribute to the Expressions revolution by participating in the pre-sale to raise funds. Lucky participants stand a chance to win a Flying Fuck NFT every month.",
      },
      {
        key: 4,
        question: "What's up with IGAF and IDGAF?",
        answer:
          "It’s all about balance, sweetie! IGAF (I Give a Flying Fck) means you care a lot, and IDGAF (I Don’t Give a Flying Fck) means you couldn’t care less. Flip that coin and let the world know where you stand!",
      },
      {
        key: 5,
        question: "Is Flying Fuck deflationary?",
        answer:
          "Yes Sugar Pie!, Every time you use Flying Fuck on DeFame Lens, it's burnt, reducing the total supply and making your remaining Flying Fucks more valuable!",
      },
      {
        key: 6,
        question:
          "Why do they call Granny Nakamoto “The Mother Theresa of Crypto”?",
        answer:
          "Because I believe everyone deserves a Flying Fuck and I’m giving away a whopping 50% of the total supply as airdrops! My grandsons and I are keeping just 1%—we’re all about sharing the love!",
      },
      {
        key: 7,
        question: "How can I join the expressions revolution?",
        answer:
          "Visit www.theflyingfuck.com, grab some Flying Fucks, Follow us on social media and recruit your loved ones into the revolution and start expressing yourself like never before! CryptoGranny guarantees it’ll be a wild ride.",
      },
      {
        key: 8,
        question: "Granny, what’s this DeFame Lens all about?",
        answer:
          "Oh sweetie, DeFame Lens is a magical browser extension that lets you see and give—or not give—a Flying Fuck to anyone on social media. No login, no data collection, just pure, unfiltered expression. Perfect for when you want to throw some shade or show some love without anyone snooping on you!",
      },
      {
        key: 9,
        question: "Is my data safe with the DeFame Lens browser extension?",
        answer:
          "Absolutely, sugar pie! No logins or data collection here. Just pure, unfiltered fun as you give and not give Flying Fucks on social media.",
      },
      {
        key: 10,
        question: "Granny, what are the three DeFame tools?",
        answer:
          "Ah, the trifecta of fun! First, there’s the DeFame Lens for pure expression on social media. Then, the DeFame Bubbles helps you spot the latest trends and Lastly, the DeFame Campaigns It's philanthropy with a twist, where every FUK given contributes to a cause's coffers, and every FLYING FUCK not given... Well, let's just say it adds a little extra burn to the digital flames.",
      },
    ],
  },
];

//Footer

export const FOOTER_ITEMS = [
  {
    title: "FLYING FUCK",
    subtitle: "Your FUCK matters now",
    quickLinks: [
      { to: "#tokenomics", key: "tokenomics", label: "Tokenomics" },
      { to: "#roadmap", key: "roadmap", label: "Roadmap" },
    ],
    contact_us: [
      { key: 1, item: "Email: DeFameLLC@gmail.com" },
      { key: 3, item: "Address: 07/04 Independence Ave, Nakamoto Ville, USA" },
    ],
    socials: [
      {
        key: 1,
        to: "https://x.com/GrannyNakamoto",
        icon: faXTwitter,
      },
      { key: 2, to: "https://t.me/+sq9bj0RyO5U4M2Jh", icon: faTelegram },
      {
        key: 3,
        to: "https://www.reddit.com/r/TheFlyingFuckCoin/",
        icon: faRedditAlien,
      },
    ],
  },
];
