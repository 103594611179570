import React from "react";
import "../../styles/Landing.css";
import CoinFlip from "../utils/CoinFlip";
import CustomButton from "../utils/CustomButton";
import { LANDING_ITEMS } from "../../constants";

function Landing() {

  return (
    <>
      <section className="landing-section">
        <div className="landing-content-wrapper">
          <div className="landing-coinflip-wrapper">
            <CoinFlip />
            <div className="glowing-container">
              <div className="rectangle"></div>
              <div className="glowing-circle"></div>
            </div>
          </div>
          <div className="landing-content">
            <div className="header-name">{LANDING_ITEMS[0].title}</div>
            <div className="styled-header">{LANDING_ITEMS[0].heading}</div>
            <div className="sub-header">{LANDING_ITEMS[0].subtitle}</div>
            <div className="landing-buttons">
              <CustomButton
                text={<span>WHITEPAPER</span>}
                className="custom-button custom-button-lg"
              />
              <CustomButton
                text={<span>BUY NOW</span>}
                className="custom-button custom-button-lg-2"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Landing;
