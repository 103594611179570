import React from "react";
import { Button } from "@mui/material";
import "../../styles/CustomButton.css";
const CustomButton = ({ text, onClick, className, ...props }) => {
  return (
    <Button className={`${className}`} onClick={onClick} {...props}>
      {text}
    </Button>
  );
};

export default CustomButton;
