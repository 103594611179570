import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FAQ_ITEMS } from "../../constants";
import "../../styles/FAQ.css";

const FAQ = () => {
  return (
    <div className="section-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {FAQ_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {FAQ_ITEMS[0].subtitle}
      </Typography>
      <Card className="faq-card">
        <CardContent>
          {FAQ_ITEMS[0].faqs.map((item, index) => (
            <Accordion key={index} className="faq-accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-title`}
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq-answer">{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </CardContent>
      </Card>
    </div>
  );
};

export default FAQ;
