import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import CustomButton from "../utils/CustomButton";
import "../../styles/About.css";
import { ABOUT_ITEMS } from "../../constants";
import { RenderContent } from "../utils/utilities";
// import aboutImage from "../../assets/blogs/crypto_granny.webp";

const About = () => {
  return (
    <div className="section-container">
      <Typography variant="h4" component="div" gutterBottom className="title">
        {ABOUT_ITEMS[0].title}
      </Typography>
      <Typography
        variant="h6"
        component="div"
        gutterBottom
        className="subtitle"
      >
        {ABOUT_ITEMS[0].subtitle}
      </Typography>
      <Card className="about-card">
        <CardContent>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <img
                src={ABOUT_ITEMS[0].image}
                alt="Granny Nakamoto"
                className="about-image"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="body1"
                component="div"
                gutterBottom
                className="content"
              >
                {RenderContent(ABOUT_ITEMS[0].content)}
              </Typography>
              <CustomButton text="Read More" className="custom-button" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default About;
