import React from "react";
import PropTypes from "prop-types";
import "../../styles/NftCard.css";

const NftCard = ({ image, title }) => {
  return (
    <div className="nft-card">
      <div className="nft-card__image-container">
        <img src={image} alt={title} className="nft-card__image" />
        <div className="nft-card__texture"></div>
      </div>
      <div className="nft-card__info">
        <h3 className="nft-card__title">{title}</h3>
      </div>
    </div>
  );
};

NftCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NftCard;
