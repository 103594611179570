import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import "../../styles/BlogCard.css";

const BlogCard = ({
  imageUrl,
  dateTime,
  title,
  subtitle,
  description,
  onReadMore,
}) => {
  return (
    <Card className="blog-card" onClick={onReadMore}>
      <img src={imageUrl} alt={title} className="blog-card-image" />
      <CardContent className="blog-card-content">
        <Typography variant="h6" component="div" className="blog-card-label">
          Blog
        </Typography>
        <Typography variant="h5" component="div" className="blog-card-title">
          {title}
        </Typography>
        <Typography
          variant="body2"
          component="div"
          className="blog-card-subtitle"
        >
          {subtitle}
        </Typography>
        <Typography variant="body2" component="div" className="blog-card-meta">
          {dateTime}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
